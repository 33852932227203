import Glide from '@glidejs/glide'

const glideSlider = () => {
  const glider = new Glide(".glide", {
    type: 'carousel',
    startAt: 1,
    perView: 3,
    gap: 20,
    length: 8,
    autoplay: 3500,
    animationDuration: 3000,
    breakpoints: {
      1200: {
        perView: 2
      },
      600: {
        perView: 1
      }
    }
  });
  glider.mount();
  // console.log("Hello from glide_slider");
}

export { glideSlider };
